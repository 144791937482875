<template>
  <div class="vg_wrapper">
    <el-row class="vd_mar10">
      <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
      <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loading"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="序号" type="index" align="center"> </el-table-column>
          <el-table-column prop="dequ_no" label="委托打样单号">
            <template slot-scope="scope">
              <div v-if="qtucForm.requ_no">{{ qtucForm.requ_no }}</div>
              <div style="display: none">{{ scope.$index }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="dequ_no" label="工厂打样单号" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="qtucForm.fequ_no">{{ qtucForm.fequ_no }}</div>
              <div style="display: none">{{ scope.$index }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="prod_no" label="我司货号"></el-table-column>
          <el-table-column prop="prod_name" label="样品名称"></el-table-column>
          <el-table-column prop="fequ_smpl_num" label="样品数量" :formatter="formatterSmplNum"></el-table-column>
          <el-table-column prop="fequ_smpl_quot" label="打样状态" :formatter="quotType"></el-table-column>
          <el-table-column prop="fequ_pedate" label="要求交样日期" :formatter="formatterSmplTime"></el-table-column>
          <el-table-column prop="fequ_taker" label="打样接收人" :formatter="formatterUser"></el-table-column>
          <el-table-column prop="fequ_pricer" label="核价接收人" :formatter="formatterUser1"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- <el-row>
      <el-col :md="24">
        <pubPagination ref="pubPagination" :totalPage = 'totalPage' @changePageSearch = "changePageSearch"></pubPagination>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import { fequAPI } from '@/api/modules/fequ';
// import pubPagination from "@/components/common/pubPagination";
import helper from '@assets/js/helper.js';

export default {
  name: 'QtucImport',
  components: {
    // pubPagination,
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        smpl_name: '',
        smpl_no: '',
        smpl_type: '',
        status: null
      },
      totalPage: 0,
      btn: {},
      loading: true,
      multiSelection: [],
      currentPage: 1
    };
  },
  created() {
    this.initData();
  },
  props: {
    qtucForm: Object,
    userList: Array
  },
  methods: {
    initData() {
      this.getData();
    },
    getData() {
      get(fequAPI.getFequSmplByFequId, { fequ_id: this.qtucForm.fequ_id })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            // this.totalPage = res.data.data.total
            // this.btn = res.data.data.btn;

            this.loading = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.smpl_id;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getData();
    },
    // 时间转换
    formatDate1(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childSuppChanel');
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childEmit', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    },
    //数量保留位数
    formatterSmplNum(val) {
      return this.helper.reservedDigits(val.fequ_smpl_num);
    },
    // 计算日期
    formatterSmplTime(val) {
      if (val.fequ_pedate) {
        return this.helper.toTimeDay(val.fequ_pedate);
      } else {
        return '暂无 ';
      }
    },
    formatterUser(val) {
      for (let i = 0; i < this.userList.length; i++) {
        if (val.fequ_taker === this.userList[i].stff_id) {
          return this.userList[i].stff_name;
        }
      }
    },
    formatterUser1(val) {
      for (let i = 0; i < this.userList.length; i++) {
        if (val.fequ_pricer === this.userList[i].stff_id) {
          return this.userList[i].stff_name;
        }
      }
    },
    // 回显状态
    quotType(val) {
      if (val.fequ_smpl_quot === 1) {
        return '打样并报价';
      } else if (val.fequ_smpl_quot === 2) {
        return '试样报价';
      } else if (val.fequ_smpl_quot === 3) {
        return '仅打样';
      } else if (val.fequ_smpl_quot === 4) {
        return '估价';
      } else if (val.fequ_smpl_quot === 5) {
        return '返工';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_mrt {
  margin-top: 20px;
}

.vd_mar10 {
  margin: 10px 0;
}
</style>
