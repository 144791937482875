<template>
  <div>
    <el-table :data="qtucForm.qutc_smpl_list" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="我司货号" prop="">
        <template slot-scope="scope">
          <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.prod_no'" label-width="0">
            <el-input size="small" disabled v-model="qtucForm.qutc_smpl_list[scope.$index].prod_no"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="更改号" prop="">
        <template slot-scope="scope">
          <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.prod_suffix'" label-width="0">
            <el-input maxlength="2" show-word-limit size="small" v-model="qtucForm.qutc_smpl_list[scope.$index].prod_suffix"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户货号" prop="prod_cust_no">
        <template slot-scope="scope">
          <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.prod_cust_no'" label-width="0">
            <el-input v-model="qtucForm.qutc_smpl_list[scope.$index].prod_cust_no" disabled maxlength="20" show-word-limit size="small"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="样品名称" prop="prod_name">
        <template slot-scope="scope">
          <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.prod_name'" label-width="0">
            <el-input v-model="qtucForm.qutc_smpl_list[scope.$index].prod_name" disabled maxlength="20" show-word-limit size="small"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="规格">
        <template slot-scope="scope">
          <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.prod_spec'" label-width="0">
            <el-input
              v-model="qtucForm.qutc_smpl_list[scope.$index].prod_spec"
              show-word-limit
              :autosize="{ minRows: 4, maxRows: 1 }"
              size="small"
              type="textarea"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品图片" width="180" align="center">
        <template slot-scope="scope">
          <!-- <template v-if="scope.row.imge_url">
            <el-image :src="helper.picUrl2(scope.row.imge_url, 'm', imgUpdatatime)" fit="cover">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          </template>
          <template v-else>
            <div style="textAlign:center"> <i class="el-icon-picture-outline"></i></div>
          </template> -->
          <span @click="clickUpdata(scope.$index)">
            <el-upload
              class="upload-demo"
              drag
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              ref="uploadMutiple"
            >
              <imgPopover v-if="scope.row.imge_id" :imgeUrl="scope.row.imge_url" :imgeUrlO="scope.row.imge_urlO"> </imgPopover>
              <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
            </el-upload>
            <span>大小不能超过10MB</span>
          </span>
        </template>
      </el-table-column>

      <!-- <el-table-column label="样品描述" show-overflow-tooltip>
        <el-table-column slot-scope="scope">
          <el-input v-model="qtucForm.qutc_smpl_list[scope.$index].value2"></el-input>
        </el-table-column>
      </el-table-column> -->
      <el-table-column label="含税价格">
        <template slot="header">
          <span style="color: red">含税价格</span>
        </template>
        <template slot-scope="scope">
          <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.qutc_smpl_price'" label-width="0" :rules="{ required: true }">
            <el-input
              v-model="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_price"
              maxlength="13"
              show-word-limit
              size="small"
              @input="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_price = helper.keepTNum1(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_price)"
              @change="
                qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_price = helper.calcPrice(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_price, 4, 100000000)
              "
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="克重">
        <template slot-scope="scope">
          <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.qutc_smpl_weight'" label-width="0">
            <el-input
              v-model="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_weight"
              maxlength="9"
              show-word-limit
              size="small"
              @input="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_weight = helper.keepTNum1(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_weight)"
              @change="
                qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_weight = helper.retain1(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_weight, 2, 1000000)
              "
            >
              <template slot="append">克</template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="装箱数">
        <!--        <template slot="header">-->
        <!--          <span style="color: red">装箱数</span>-->
        <!--        </template>-->
        <template slot-scope="scope">
          <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.qutc_smpl_qpc'" label-width="0">
            <el-input
              v-model="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_qpc"
              maxlength="10"
              show-word-limit
              size="small"
              @input="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_qpc = helper.pureNumber(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_qpc)"
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="箱规" min-width="120">
        <!--        <template slot="header">-->
        <!--          <span style="color: red">箱规</span>-->
        <!--        </template>-->
        <template slot-scope="scope">
          <div class="dix">
            <span>纸箱长:</span>
            <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.qutc_smpl_boxl'" label-width="0">
              <el-input
                v-model="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxl"
                maxlength="9"
                show-word-limit
                size="small"
                @input="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxl = helper.keepTNum1(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxl)"
                @change="
                  qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxl = helper.retain1(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxl, 2, 1000000)
                "
              >
                <template slot="append">cm</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="dix">
            <span>纸箱宽:</span>
            <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.qutc_smpl_boxw'" label-width="0" :rules="{ required: true }">
              <el-input
                v-model="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxw"
                maxlength="9"
                show-word-limit
                size="small"
                @input="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxw = helper.keepTNum1(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxw)"
                @change="
                  qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxw = helper.retain1(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxw, 2, 1000000)
                "
              >
                <template slot="append">cm</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="dix">
            <span>纸箱高:</span>
            <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.qutc_smpl_boxh'" label-width="0" :rules="{ required: true }">
              <el-input
                v-model="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxh"
                maxlength="9"
                show-word-limit
                size="small"
                @input="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxh = helper.keepTNum1(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxh)"
                @change="
                  qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxh = helper.retain1(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_boxh, 2, 1000000)
                "
              >
                <template slot="append">cm</template>
              </el-input>
            </el-form-item>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.qutc_smpl_remark'" label-width="0">
            <el-input
              v-model="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_remark"
              maxlength="255"
              show-word-limit
              :autosize="{ minRows: 4, maxRows: 1 }"
              size="small"
              type="textarea"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="起订量">
        <template slot="header">
          <span style="color: red">起订量</span>
        </template>
        <template slot-scope="scope">
          <el-form-item :prop="'qutc_smpl_list.' + scope.$index + '.qutc_smpl_moq'" label-width="0" :rules="{ required: true }">
            <el-input
              v-model="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_moq"
              maxlength="10"
              show-word-limit
              size="small"
              @input="qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_moq = helper.pureNumber(qtucForm.qutc_smpl_list[scope.$index].qutc_smpl_moq)"
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import imgPopover from '@/views/component/imgPopover';
import { imgeAPI } from '@api/modules/imge';
import helper from '@assets/js/helper';

export default {
  name: 'QtucTab',
  components: {
    imgPopover
  },
  props: {
    qtucForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      smplFlag: 0,
      validatePass: (rule, value, callback) => {
        let that = this;
        if (value) {
          if (that.smplFlag === 2) {
            callback(new Error(''));
          } else if (that.smplFlag === 0) {
            callback(true);
          }
        }
      },
      selectionsqutc_smpl_list: [],
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      selectRow: null,
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      selectFlag: false,
      beloType: [],
      imgUpdatatime: parseInt(Math.random() * 100000000)
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getContry();
    },
    // 获取打样部门
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let beloType = res.data.data.form.optn_cntt_qutc_smpl_list;
            for (let item of beloType) {
              item.param2 = Number(item.param2);
            }
            this.beloType = beloType;
          }
        })
        .catch(() => {});
    },
    // 跳转样品工单
    jumpPodrProd(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/smpl_edit', { perm_id: 112, form_id: row.smpl_id });
    },

    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsqutc_smpl_list = [];
      this.selectionsqutc_smpl_list = selection;
      this.$emit('handleSelectionChange', this.selectionsqutc_smpl_list);
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.qtucForm.qutc_smpl_list[this.selectRow].imge_id = res.data.imge_id;
        this.$set(this.qtucForm.qutc_smpl_list[this.selectRow], 'imge_url', this.helper.picUrl(urlEng, 'm', updateTime));
        this.$set(this.qtucForm.qutc_smpl_list[this.selectRow], 'imge_urlO', this.helper.picUrl(urlEng, 'l', updateTime));
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 10MB!');
        return false;
      }
    },
    clickUpdata(index) {
      this.selectRow = index;
    }
  }
};
</script>

<style scoped lang="scss">
.el-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dix {
  display: flex;
  align-items: center;
  > span {
    min-width: 60px;
  }
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

::v-deep .el-upload--text {
  height: 120px;
  width: 120px;
}

::v-deep .el-upload-dragger {
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
