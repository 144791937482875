<template>
  <div class="qtucEditMain">
    <div class="vd_edhea vg_button_group">
      <editHeader :btn="btn" :isShow="isShow" :strForm="qtucForm" @closeEdit="closeEdit" @openEdit="openEdit" @submit="submit('qtucForm')" />
      <div class="vd_export" v-if="isShow">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新 </el-button>
        <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrint" v-if="qtucForm.status">打印 </el-button>
        <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExport" v-if="qtucForm.status">下载PDF</el-button>
      </div>
      <el-col :md="4" class="vd_export2">
        <span style="margin-right: 2px; width: 150px">简易报价单号:</span>
        <el-input size="small" v-model="qtucForm.qutc_no" disabled show-word-limit> </el-input>
      </el-col>
    </div>
    <el-form ref="qtucForm" :model="qtucForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="8">
          <el-form-item label="委托打样单号" prop="requ_no">
            <el-input v-model="qtucForm.requ_no" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="工厂打样单号" prop="fequ_no">
            <el-input v-model="qtucForm.fequ_no" disabled></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_abbr">
            <el-input v-model="qtucForm.cust_abbr" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="供应商简称" prop="supp_abbr">
            <el-input v-model="qtucForm.supp_abbr" disabled></el-input>
          </el-form-item>
        </el-col>
        <!--        <el-col :md="8">
          <el-form-item label="货号后缀" prop="prod_suffix">
            <el-input maxlength="2" v-model="qtucForm.prod_poststfix" show-word-limit></el-input>
          </el-form-item>
        </el-col>-->
        <el-col :md="8">
          <el-form-item label="核价日期" prop="qutc_pridate">
            <el-date-picker v-model="qtucForm.qutc_pridate" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="核价接收人" prop="qutc_pricer">
            <el-select disabled v-model="qtucForm.qutc_pricer">
              <el-option v-for="item in StffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortopb">
        <div class="vd_but">
          <el-button type="success" plain size="small" @click="importTab()">导入</el-button>
          <el-button type="danger" plain size="small" @click="clickDel">删除</el-button>
        </div>
        <tableA :qtucForm="qtucForm" @handleSelectionChange="handleSelectionChange"> </tableA>
      </el-row>
    </el-form>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="70%">
      <importA @childEmit="childEmit" :qtucForm="qtucForm" :userList="StffList" />
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import tableA from './cpn/tableA';
import importA from './cpn/importA.vue';
import { stffAPI } from '@api/modules/staff';
import { get, post } from '@api/request';
import { qtucAPI } from '@api/modules/qtuc';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'QtucEditMain',
  components: {
    editHeader,
    inputUser,
    tableA,
    importA
  },
  data() {
    return {
      isShow: true,
      btn: {},
      dialogVisible: false,
      qtucForm: {
        requ_id: null, //委托打样单号
        supp_abbr: null, //供应商简称
        cust_abbr: null, //客户简称
        qutc_no: null, //报价单号
        qutc_pricer: null, //核价接收人
        qutc_pridate: null, //核价日期
        fequ_no: null,
        qutc_id: null,
        qutc_smpl_list: []
      },
      StffList: [],
      rules: {
        requ_id: [{ required: true, message: ' ', trigger: 'blur' }],
        supp_abbr: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_abbr: [{ required: true, message: ' ', trigger: 'blur' }],
        fequ_no: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      stffForm: {},
      selectTab: [],
      delList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getRequStffList();
      this.getQtucInfo();
    },
    getQtucInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.qtucForm.qutc_id = props.form_id;
      get(qtucAPI.getQutcById, { qutc_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.btn = res.data.data.btn;
            this.qtucForm = res.data.data.form;
            let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
            this.qtucForm.qutc_smpl_list.forEach(item => {
              let image_url = item.imge_url;

              item.imge_url = this.helper.picUrl(image_url, 'm', updateTime);
              item.imge_urlO = this.helper.picUrl(image_url, 'l', updateTime);
            });
            this.stffForm.stff_name = this.qtucForm.stff_name;
            this.stffForm.dept_name = this.qtucForm.dept_name;
            this.stffForm.dept_team_name = this.qtucForm.dept_team_name;
            this.stffForm.user_id = this.qtucForm.user_id;
            this.stffForm.dept_id = this.qtucForm.dept_id;
            this.stffForm.stff_id = this.qtucForm.stff_id;
            if (this.qtucForm.qutc_pridate === 0) {
              this.qtucForm.qutc_pridate = null;
            } else {
              this.qtucForm.qutc_pridate = this.qtucForm.qutc_pridate * 1000;
            }
            if (!this.qtucForm.qutc_pridate) {
              this.qtucForm.qutc_pridate = this.qtucForm.create_time * 1000;
            }
            //  this.qtucForm.qutc_pricer+=''
            this.qtucForm.qutc_smpl_list.forEach(item => {
              item.qutc_smpl_price = this.helper.haveFour(item.qutc_smpl_price);
            });
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          // this.show = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    saveInfo() {
      const props = {};
      props.qutc_pricer = this.qtucForm.qutc_pricer;
      props.qutc_id = this.qtucForm.qutc_id;
      props.prod_suffix = this.qtucForm.prod_suffix;
      this.delList.forEach(item => {
        let flag = this.qtucForm.qutc_smpl_list.some(item2 => {
          item2.fequ_smpl_id === item.fequ_smpl_id;
        });
        if (!flag) {
          this.qtucForm.qutc_smpl_list.push(item);
        }
      });

      props.qutc_smpl_list = this.qtucForm.qutc_smpl_list;

      if (!this.qtucForm.qutc_pridate) {
        props.qutc_pridate = 0;
      } else {
        props.qutc_pridate = this.helper.getNewDate(this.qtucForm.qutc_pridate);
      }
      post(qtucAPI.editQutc, props)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    buttonRefresh() {
      this.initData();
    },
    importTab() {
      this.dialogVisible = true;
    },
    getRequStffList() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.StffList = res.data.data;
          }
        })
        .catch(() => {});
    },
    childEmit(val) {
      let value = val;
      value = value.filter(item => this.qtucForm.qutc_smpl_list.every(item1 => item.fequ_smpl_id !== item1.fequ_smpl_id));
      this.qtucForm.qutc_smpl_list.forEach(item => {
        if (item.destroy_flag === undefined) {
          item.destroy_flag = 0;
        }
      });
      this.qtucForm.qutc_smpl_list = this.qtucForm.qutc_smpl_list.concat(value);
      this.dialogVisible = false;
    },
    clickDel() {
      if (this.selectTab.length === 0) {
        return this.$message.info('至少选择一条信息');
      }
      this.$confirm('确定移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.selectTab.forEach(item => {
            this.qtucForm.qutc_smpl_list.forEach((item2, index2) => {
              if (item.fequ_smpl_id === item2.fequ_smpl_id) {
                if (this.qtucForm.qutc_smpl_list[index2].destroy_flag !== 0) {
                  let flag = this.delList.some(item => {
                    return item.fequ_smpl_id === this.qtucForm.qutc_smpl_list[index2].fequ_smpl_id;
                  });
                  if (!flag) {
                    this.qtucForm.qutc_smpl_list[index2].destroy_flag = 1;
                    this.delList.push(this.qtucForm.qutc_smpl_list[index2]);
                  }
                }

                this.qtucForm.qutc_smpl_list.splice(index2, 1);
              }
            });
          });
        })
        .catch(() => {});
    },
    handleSelectionChange(row) {
      this.selectTab = row;
    },
    //打印
    clickPrint() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.qtucForm.qutc_id;
          get(qtucAPI.exportQutcPdfById, { qutc_id: str })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }
                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExport() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.qtucForm.qutc_id;
          get(qtucAPI.exportQutcPdfById, { qutc_id: str })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, '简易报价单.pdf');
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="scss">
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export1 {
  position: absolute;
  top: 0;
  left: 145px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_bortopb {
  border-top: #dcdfe6 dashed 1px;
  padding-top: 20px;
}

.vd_mt_5 {
  margin-top: 5px;
}

.vd_but {
  margin-bottom: 10px;
}
</style>
